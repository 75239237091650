* {
    outline: none;
    box-sizing: border-box;
    -ms-overflow-style: none !important;
    /* IE and Edge */

}

@media screen and (max-width : 1500px) {
    ::-webkit-scrollbar {
        display: none !important;
    }
}

::-webkit-scrollbar {
    width: 16px;
    height: 16px;
    display: none;
}

::-webkit-scrollbar-track-piece {
    background-color: transparent;
}

::-webkit-scrollbar-thumb:vertical {
    height: 30px;
    background-color: #18243B;
}

html,
body,
#root {
    font-family: "Rubik";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #0D0D2B;
    min-height: 100vh;
    /* line-height: 140%; */
}

body {
    margin: 0;
    box-sizing: border-box;
}

.app {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-flow: column;
    flex-direction: column;
    z-index: 1;
    background-size: cover;
    font-family: "Satoshi";
    transition: all ease 0.33ms;
}

.MuiDrawer-paperAnchorDockedLeft {
    border-right: 0 !important;
}


.ohm-pairs {
    display: flex;
    align-items: center;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
}

input[type="number"] {
    -moz-appearance: textfield !important;
}

a:hover svg {
    color: inherit;
}

.MuiLink-root.active {
    color: #ffe300 !important;
    text-decoration: underline;
}

.ReactModal__Overlay {
    background-color: rgba(0, 0, 0, 0.8) !important;
}

.ReactModal__Content {
    border: none !important;
    background: rgba(41, 41, 41, 0.8) !important;
    overflow: unset !important;
}

.ReactModalPortal {
    position: relative;
    z-index: 10;
}

.MuiSkeleton-root {
    background-color: rgba(150, 150, 150, 0.3) !important;
}

.highcharts-label>text {
    fill: #1fc7d4 !important;
}

#WEB3_CONNECT_MODAL_ID {
    position: relative;
    z-index: 1000;
}

.MuiDialog-container>div {
    background-color: transparent !important;
    max-width: unset;
    margin: 0px !important;

}

.MuiDialog-paper {
    overflow: hidden !important;
}

.react-multi-carousel-list {
    position: unset !important;
}

.react-multi-carousel-item {
    display: flex !important;
    justify-content: center !important;
}

.MuiSlider-rail {
    background-color: white !important;
    opacity: 1 !important;
}

.MuiSlider-thumb {
    background-color: #43BAD1 !important;
    width: 24px !important;
    height: 24px !important;


}

@media screen and (max-width : 450px) {
    .MuiSlider-thumb {
        width: 22px !important;
        height: 22px !important;
    }
}

.MuiSlider-track {
    background-color: #43BAD1 !important;
}

.MuiSvgIcon-root {
    font-size: 32px !important;
}

.MuiCheckbox-root {
    color: #f50057 !important;
}
.apexcharts-xaxis{
    display : none!important;
}
.apexcharts-grid{
    display : none!important;
}